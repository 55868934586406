body {
  background-image: linear-gradient(#465a69,#203647);
  background-attachment: fixed;
}

main {
  background: url('https://www.datenspender.de/images/background.svg');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-attachment: fixed;

  padding: 0 15px;
  margin: 8px auto 40px;
}

.dd-pollcard {
  margin-bottom: 30px;
  margin-top: 30px;
}

.dd-chart {
  margin: 30px auto;
}

.dd-metric {
  padding-right: 30px;
}

.dd-metric__label {
  padding-left: 10px;
  vertical-align: middle;
}

.c-header {
  padding-top: 80px;
}

.c-logo--hashtag {
  max-width: 600px;
}
